import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const CenterOfReproductiveMedicineLandingPage = () => (
  <PhysicianLandingPage
    physician="Center of Reproductive Medicine"
    institution="Center of Reproductive Medicine"
    referralCode="INFERTILITYTEXAS"
  />
)

export default CenterOfReproductiveMedicineLandingPage
